<template>
    <div>
        <b-container>
            <b-row align-h="center">
                <b-col md="6">
                    <b-col cols="12" v-if="errors.length">
                        <b>Please correct the following error(s):</b>
                        <ul>
                            <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                        </ul>
                    </b-col>

                    <b-form @submit.prevent="onSubmit"
                            name="contact-us"
                            method="post">

                        <input type="hidden" name="username" v-model="form.username"/>

                        <b-row>
                            <b-col cols="12" class="pb-3">
                                <label class="sr-only" for="old_pass">Current password</label>
                                <b-input
                                        id="old_pass"
                                        name="old_pass"
                                        class="mb-2 mr-sm-2 mb-sm-0"
                                        placeholder="Current password"
                                        type="password"
                                        v-model="form.old_pass"
                                        required
                                ></b-input>
                            </b-col>

                            <b-col cols="12" class="pb-3">
                                <label class="sr-only" for="new_pass">New password</label>
                                <b-input
                                        id="new_pass"
                                        name="new_pass"
                                        class="mb-2 mr-sm-2 mb-sm-0"
                                        placeholder="New password"
                                        type="password"
                                        v-model="form.new_pass"
                                        required
                                ></b-input>
                            </b-col>

                            <b-col cols="12" class="pb-3">
                                <label class="sr-only" for="conf_password">Confirm password</label>
                                <b-input
                                        id="conf_password"
                                        name="conf_password"
                                        class="mb-2 mr-sm-2 mb-sm-0"
                                        placeholder="Confirm password"
                                        type="password"
                                        v-model="form.new_pass_conf"
                                        required
                                ></b-input>
                            </b-col>

                            <b-col cols="12">
                                <b-button type="submit" class="default-button">Submit</b-button>
                                <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import config from 'config';
    import axios from "axios";

    export default {
        name: "ChangePassword",
        data () {
            return {
                errors: [],
                form: {
                    username: '',
                    old_pass: '',
                    new_pass: '',
                    new_pass_conf: ''
                },
                loading: false
            }
        },
        methods: {
            onSubmit: function() {
                this.errors.length = 0;

                if (this.form.old_pass === '') {
                    this.errors.push('Please enter your current password');
                }
                if (this.form.new_pass === '') {
                    this.errors.push('Please enter your new password');
                }
                if (this.form.new_pass_conf === '') {
                    this.errors.push('Please confirm your password');
                }
                if (this.form.new_pass.localeCompare(this.form.new_pass_conf) !== 0) {
                    this.errors.push('Password mismatch');
                }

                if (this.errors.length === 0) {
                    this.loading = true;

                    let formData = new FormData();
                    formData.append('username', this.form.username);
                    formData.append('old_pass', this.form.old_pass);
                    formData.append('new_pass', this.form.new_pass);

                    axios.post( `${config.apiUrl}/login/password`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
                            }
                        }
                    ).then(() => {
                        this.loading = false;
                        this.$store.dispatch('alert/success', 'Password changed. Please log in again.', {root: true});
                        this.$router.push('/login');
                    }).catch((e) => {
                        this.loading = false;
                        if (e.response) {
                            console.log(e.response.status)
                            if (e.response.status === 403) {
                                this.$store.dispatch('alert/error', 'Session expired. Please login again.', {root: true});
                                this.$router.push('/login');
                            } else if (e.response.status === 502) {
                                this.$store.dispatch('alert/error', 'Server error.', {root: true});
                            } else if (e.response.data.message) {
                                this.$store.dispatch('alert/error', e.response.data.message, { root: true });
                            } else {
                                this.$store.dispatch('alert/error', e.response.data, { root: true });
                            }
                        }
                    });
                }
            }
        },
        mounted() {
            const currentUser = localStorage.getItem('user');
            this.form.username = JSON.parse(currentUser).username;
        }
    }
</script>

<style scoped>

</style>
